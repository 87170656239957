<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden z-10"
      @close="close"
      :open="open"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <form
                @submit.prevent="save"
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-cyan-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        New {{ type }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          class="bg-cyan-700 rounded-md text-cyan-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          @click="close"
                          required="true"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-cyan-300">
                        {{ description }}
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label
                            for="project_name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Tag ID
                          </label>
                          <div class="mt-1">
                            <input
                              autofocus
                              autocomplete="off"
                              type="text"
                              name="project_name"
                              id="tag_id"
                              v-model="id"
                              class="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            for="description"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Description
                          </label>
                          <div class="mt-1">
                            <textarea
                              autocomplete="off"
                              id="description"
                              name="description"
                              rows="4"
                              class="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    type="button"
                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    @click="close"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  name: "SlideOver",
  props: { type: String },
  data() {
    return {
      id: ""
    };
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon
  },
  setup() {
    return {};
  },
  computed: {
    description() {
      return this.type == "Basket"
        ? "Create a new basket by entering its Tag ID or by reading it's QR code."
        : "Create a new tag by entering its Transponder ID or by reading it's RFID transponder.";
    },
    slideOver() {
      return this.$store.state.slideOver;
    },
    open() {
      return this.slideOver ? true : false;
    }
  },
  methods: {
    save() {
      if (this.id === "") {
        const alert = {
          type: "Error",
          title: "No ID given",
          description: "Please provide a valid ID."
        };
        this.$store.dispatch("setAlert", alert);
        return;
      } else {
        this.$emit("add", this.id);
        this.id = "";
        this.close();
      }
    },
    close() {
      this.$store.dispatch("setSlideOver", false);
    }
  }
};
</script>
