<template>
  <div class="text-gray-800">
    <div class="md:flex md:items-center md:justify-between mb-5">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Locations
        </h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        >
          Relocate
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <figure>
        <img
          class="w-full rounded-lg"
          src="@/assets/locations.png"
          alt=""
          width="1310"
          height="873"
        />
      </figure>
    </div>
  </div>
</template>
