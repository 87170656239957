<template>
  <div>
    <div class="md:flex md:items-center md:justify-between mb-5">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Activity History
        </h2>
      </div>
    </div>
    <div class="pt-5">Not implemented yet</div>
  </div>
</template>

<script>
export default {
  name: "History",
  setup() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
