<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-20"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 divide-x divide-gray-200"
        >
          <div class="w-0 flex-1 flex items-center p-4">
            <div class="w-full">
              <p class="text-sm font-medium text-gray-900">
                Update available
              </p>
              <p class="mt-1 text-sm text-gray-500">
                There is a new version of this application available. Please
                update your app now.
              </p>
            </div>
          </div>
          <div class="flex">
            <div class="flex flex-col divide-y divide-gray-200">
              <div class="h-0 flex-1 flex">
                <button
                  @click="refreshApp"
                  class="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-cyan-600 hover:text-cyan-500 focus:outline-none focus:z-10 focus:ring-2 focus:ring-cyan-500"
                >
                  Update now
                </button>
              </div>
              <div class="h-0 flex-1 flex">
                <button
                  @click="later"
                  class="w-full border border-transparent rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                >
                  Later
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import update from "../mixins/update";
export default {
  data() {
    return {
      dismiss: false
    };
  },
  methods: {
    later() {
      this.dismiss = true;
    }
  },
  computed: {
    show() {
      return this.updateExists && !this.dismiss;
    }
  },
  mixins: [update]
};
</script>
