import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Baskets from "../views/Baskets.vue";
import Tags from "../views/Tags.vue";
import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Locations from "../views/Locations.vue";
import History from "../views/History.vue";
import firebase from "firebase/app";
import "firebase/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: "/baskets",
    name: "Baskets",
    component: Baskets,
    meta: {
      auth: true
    }
  },
  {
    path: "/tags",
    name: "Tags",
    component: Tags,
    meta: {
      auth: true
    }
  },
  {
    path: "/locations",
    name: "Locations",
    component: Locations,
    meta: {
      auth: true
    }
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: {
      auth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      auth: true
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    }
  } else {
    next();
  }
});

export default router;
