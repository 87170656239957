import { createStore } from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { db } from "../firebase";

import { firestoreOptions } from "vuexfire";
firestoreOptions.wait = true;

export default createStore({
  state: {
    company: {
      id: "yFyEPw5bkFupm1NChEXI",
      name: "ZARGES GmbH"
    },
    activeTrolleyId: "ybNsUQSvB69lErKbqdLY",
    activeTrolley: {},
    alert: false,
    slideOver: false,
    userName: "",
    modal: false
  },
  mutations: {
    setAlert(state, value) {
      state.alert = value;
      if (value) {
        setTimeout(() => {
          state.alert = false;
        }, 3000);
      }
    },
    setSlideOver(state, value) {
      state.slideOver = value;
    },
    setUserName(state, value) {
      state.userName = value;
    },
    setModal(state, value) {
      state.modal = value;
    },
    ...vuexfireMutations
  },
  actions: {
    bindActiveTrolley: firestoreAction(({ bindFirestoreRef, state }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        "activeTrolley",
        db
          .collection("companies")
          .doc(state.company.id)
          .collection("trolleys")
          .doc(state.activeTrolleyId)
      );
    }),
    unbindActiveTrolley: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("activeTrolley");
    }),
    setAlert(context, value) {
      context.commit("setAlert", value);
    },
    setSlideOver(context, value) {
      context.commit("setSlideOver", value);
    },
    setUserName(context, value) {
      context.commit("setUserName", value);
    },
    setModal(context, value) {
      context.commit("setModal", value);
    }
  },
  modules: {}
});
