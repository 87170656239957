<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="close"
      :open="open"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div>
              <div
                v-if="countingProgress == 100"
                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
              >
                <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  {{ modalTitle }}
                </DialogTitle>
                <div
                  v-if="countingProgress < 100"
                  class="flex flex-wrap p-4 mt-2"
                >
                  <base-progress
                    :percentage="countingProgress"
                    class="mx-2 mb-2 h-5"
                  >
                    <span
                      class="text-xs text-white w-full flex justify-end pr-2"
                      >{{ countingProgress }}%</span
                    >
                  </base-progress>
                </div>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ modalDescription }}
                  </p>
                </div>
                <div class="mt-2" v-if="countingProgress == 100">
                  Tags: {{ resultKnownTags.length }} - Baskets:
                  {{ resultKnownBaskets.length }} - Unknown Transponders:
                  {{ resultUnknownIds.length }}
                </div>
                <div class="mt-2" v-if="!this.activeTrolley.read.isClosed">
                  <p class="text-sm text-red-500 p-1 rounded-lg bg-red-200">
                    Caution: The MPO door is not closed! This can cause wrong
                    numbers.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-countingProgress-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:col-start-2 sm:text-sm"
                v-bind:style="{ opacity: buttonOpacity, cursor: buttonCursor }"
                @click="book"
                :disabled="countingProgress < 100"
              >
                Book
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                v-bind:style="{ opacity: buttonOpacity }"
                @click="cancel"
                ref="cancelButtonRef"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import BaseProgress from "@/components/BaseProgress.vue";

import { db } from "../firebase";
import firebase from "firebase/app";

export default {
  name: "Modal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    BaseProgress
  },
  data() {
    return {
      countingProgress: 0
    };
  },
  setup() {
    return {};
  },
  methods: {
    updateProgress() {
      this.countingProgress++;
      if (this.countingProgress < 100) {
        let self = this;
        setTimeout(function() {
          self.updateProgress();
        }, 50);
      }
    },
    book() {
      if (this.countingProgress === 100) {
        let historyEntry = false;
        let read = false;
        if (this.activeTrolley.read.status === "done") {
          if (this.activeTrolley.read.isClosed) {
            historyEntry = {
              count: this.resultKnownTags.length,
              message: "Inventory booked",
              name: this.userName,
              status: "success",
              timestamp: { seconds: +new Date(), milliseconds: 0 }
            };
          } else {
            historyEntry = {
              count: this.resultKnownTags.length,
              message: "Door was opened",
              name: this.userName,
              status: "error",
              timestamp: { seconds: +new Date(), milliseconds: 0 }
            };
          }
          read = {
            name: this.activeTrolley.read.name,
            tags: this.activeTrolley.read.tags,
            status: "success",
            isClosed: this.activeTrolley.read.isClosed,
            timestamp: this.activeTrolley.read.timestamp
          };
        } else {
          historyEntry = {
            count: "0",
            message: "Unknown reading error",
            name: this.userName,
            status: "failed",
            timestamp: { seconds: +new Date(), milliseconds: 0 }
          };
          read = {
            name: this.activeTrolley.read.name,
            tags: this.activeTrolley.read.tags,
            status: "failed",
            isClosed: this.activeTrolley.read.isClosed,
            timestamp: this.activeTrolley.read.timestamp
          };
        }

        db.collection("companies")
          .doc(this.$store.state.company.id)
          .collection("trolleys")
          .doc(this.$store.state.activeTrolleyId)
          .update({
            read
          })
          .then(() => {
            console.log("Completed");
          });

        if (!this.$store.state.activeTrolley.transactions)
          this.$store.state.activeTrolley.transactions = [];
        this.$store.state.activeTrolley.transactions.push(historyEntry);
        db.collection("companies")
          .doc(this.$store.state.company.id)
          .collection("trolleys")
          .doc(this.$store.state.activeTrolleyId)
          .update({
            transactions: this.$store.state.activeTrolley.transactions
          })
          .then(() => {
            console.log("Transaction added");
          });

        this.close();
      }
    },
    cancel() {
      const read = {
        name: this.activeTrolley.read.name,
        tags: this.activeTrolley.read.tags,
        status: "canceled",
        isClosed: this.activeTrolley.read.isClosed,
        timestamp: this.activeTrolley.read.timestamp
      };
      db.collection("companies")
        .doc(this.$store.state.company.id)
        .collection("trolleys")
        .doc(this.$store.state.activeTrolleyId)
        .update({
          read
        })
        .then(() => {
          console.log("Completed");
        });
      this.close();
    },
    close() {
      this.$store.dispatch("setModal", false);
    }
  },
  computed: {
    modalTitle() {
      return this.countingProgress === 100
        ? "Ready to book"
        : "Counting MPO Content";
    },
    modalDescription() {
      return this.countingProgress === 100
        ? "Counting of Tags and Baskets done. Do you want to book?"
        : "The system is reading the RFID Transponders within the MPO trolley. This can last up to 10 seconds.";
    },
    buttonOpacity() {
      return this.countingProgress === 100 ? 1 : 0.5;
    },
    buttonCursor() {
      return this.countingProgress === 100 ? "pointer" : "default";
    },
    open() {
      return this.$store.state.modal;
    },
    userName() {
      return this.$store.state.userName;
    },
    activeTrolley() {
      return this.$store.state.activeTrolley;
    },
    resultKnownTags() {
      let result = [];
      this.activeTrolley.read.tags.forEach(tag => {
        if (this.activeTrolley.tags.indexOf(tag) > -1) {
          result.push(tag);
        }
      });
      return result;
    },
    resultKnownBaskets() {
      let result = [];
      this.activeTrolley.read.tags.forEach(tag => {
        if (this.activeTrolley.baskets.indexOf(tag) > -1) {
          result.push(tag);
        }
      });
      return result;
    },
    resultUnknownIds() {
      let result = [];
      this.activeTrolley.read.tags.forEach(tag => {
        if (
          this.activeTrolley.tags.indexOf(tag) === -1 &&
          this.activeTrolley.baskets.indexOf(tag) === -1 &&
          tag != ""
        ) {
          result.push(tag);
        }
      });
      return result;
    }
  },
  watch: {
    open: function(val) {
      if (val) {
        db.collection("companies")
          .doc(this.$store.state.company.id)
          .collection("trolleys")
          .doc(this.$store.state.activeTrolleyId)
          .update({
            read: {
              name: this.userName,
              tags: [],
              status: "initiating",
              isClosed: true,
              timestamp: firebase.firestore.FieldValue.serverTimestamp()
            }
          })
          .then(() => {
            console.log("Read initiated");
          });

        this.countingProgress = 0;
        this.updateProgress();
      }
    }
  }
};
</script>
