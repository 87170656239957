<template>
  <div>
    <div class="md:flex md:items-center md:justify-between mb-5">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Baskets ({{ this.$store.state.activeTrolley.baskets.length }})
        </h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <button
          type="button"
          @click="create"
          class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        >
          Create
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(basket, basketIdx) in activeTrolley.baskets"
                  :key="basket"
                  :class="basketIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ basket }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a
                      href="#"
                      class="text-cyan-600 hover:text-cyan-900"
                      @click="remove(basket)"
                      >Delete</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <SlideOver type="Basket" @add="add" />
  </div>
</template>

<script>
import { db } from "../firebase";
import SlideOver from "@/components/SlideOver.vue";
export default {
  name: "Baskets",
  components: {
    SlideOver
  },
  setup() {
    return {};
  },
  computed: {
    activeTrolley() {
      return this.$store.state.activeTrolley;
    }
  },
  methods: {
    create() {
      this.$store.dispatch("setSlideOver", true);
    },
    add(id) {
      if (this.$store.state.activeTrolley.baskets.indexOf(id) === -1) {
        this.$store.state.activeTrolley.baskets.push(id);
        db.collection("companies")
          .doc(this.$store.state.company.id)
          .collection("trolleys")
          .doc(this.$store.state.activeTrolleyId)
          .update({ baskets: this.$store.state.activeTrolley.baskets })
          .then(() => {
            console.log("Basket added");
          });
      } else {
        const alert = {
          type: "Error",
          title: "Duplicate entry",
          description: "The basket you try to create already exists."
        };
        this.$store.dispatch("setAlert", alert);
        return;
      }
    },
    remove(id) {
      const index = this.$store.state.activeTrolley.baskets.indexOf(id);
      if (index > -1) {
        this.$store.state.activeTrolley.baskets.splice(index, 1);
        db.collection("companies")
          .doc(this.$store.state.company.id)
          .collection("trolleys")
          .doc(this.$store.state.activeTrolleyId)
          .update({ baskets: this.$store.state.activeTrolley.baskets })
          .then(() => {
            console.log("Basket removed");
          });
      }
    }
  }
};
</script>
