<template>
  <!-- Page header -->
  <div class="bg-white shadow">
    <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <div
        class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200"
      >
        <div class="flex-1 min-w-0">
          <!-- Profile -->
          <div class="flex items-center">
            <img
              class="hidden h-16 w-16 rounded-full sm:block"
              src="https://files.zarges.com/products/images/high/ZARGES_MPO_VERSO_1ISO_LAENGS_PROD_TECH_ST_01.jpg"
              alt=""
            />
            <div>
              <div class="flex items-center">
                <img
                  class="h-16 w-16 rounded-full sm:hidden"
                  src="https://files.zarges.com/products/images/high/ZARGES_MPO_VERSO_1ISO_LAENGS_PROD_TECH_ST_01.jpg"
                  alt=""
                />
                <h1
                  class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate"
                >
                  {{ activeTrolley.name }}
                </h1>
              </div>
              <dl
                class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap"
              >
                <dt class="sr-only">Company</dt>
                <dd
                  class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6"
                >
                  <LocationMarkerIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Weilheim, Building 2, Level 3
                </dd>
                <dt class="sr-only">Account status</dt>
                <dd
                  class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize"
                >
                  <CheckCircleIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  Online
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
          <router-link
            v-if="!isReadInProgress"
            to="./locations"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Relocate
          </router-link>
          <button
            v-if="!isReadInProgress"
            type="button"
            @click="openModal"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Count and book
          </button>
          <div v-if="isReadInProgress" class="flex flex-row">
            <div
              class="flex-col font-medium text-sm text-gray-500 px-5 text-right place-content-center"
            >
              <p class="text-cyan-700">
                Read in progress
              </p>
              <p class="text-xs font-normal">
                triggered by {{ activeTrolley.read.name }}
              </p>
            </div>
            <CssSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-lg leading-6 font-medium text-gray-900">
        Overview
      </h2>
      <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <!-- Card -->
        <div
          v-for="card in cards"
          :key="card.name"
          class="bg-white overflow-hidden shadow rounded-lg"
        >
          <div class="p-5">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <component
                  :is="card.icon"
                  class="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm font-medium text-gray-500 truncate">
                    {{ card.name }}
                  </dt>
                  <dd class="flex items-baseline">
                    <p class="text-2xl font-semibold text-gray-900">
                      {{ card.amount }}
                    </p>
                    <p
                      v-if="card.changeType"
                      :class="[
                        card.changeType === 'increase'
                          ? 'text-green-600'
                          : 'text-red-600',
                        'ml-2 flex items-baseline text-sm font-semibold'
                      ]"
                    >
                      <ArrowSmUpIcon
                        v-if="card.changeType === 'increase'"
                        class="self-center flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <ArrowSmDownIcon
                        v-else
                        class="self-center flex-shrink-0 h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                      <span class="sr-only">
                        {{
                          card.changeType === "increase"
                            ? "Increased"
                            : "Decreased"
                        }}
                        by
                      </span>
                      {{ getTransactionDifference(0) }}
                    </p>
                    <div
                      class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6"
                    >
                      <div class="text-sm">
                        <router-link
                          :to="card.href"
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          View all</router-link
                        >
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-5 py-3">
            <div class="text-sm">
              <router-link
                :to="card.href"
                class="font-medium text-cyan-700 hover:text-cyan-900"
              >
                View all
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2
      class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8"
    >
      Recent activity
    </h2>

    <!-- Activity list (smallest breakpoint only) -->
    <div class="shadow sm:hidden">
      <ul
        class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
      >
        <li
          v-for="(transaction, transactionIndex) in transactionsPage"
          :key="transaction.id"
        >
          <a
            :href="transaction.href"
            class="block px-4 py-4 bg-white hover:bg-gray-50"
          >
            <span class="flex items-center space-x-4">
              <span class="flex-1 flex space-x-2 truncate">
                <CashIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span class="flex flex-col text-gray-500 text-sm truncate">
                  <span class="truncate">{{ transaction.name }}</span>
                  <span
                    ><span class="text-gray-900 font-medium">{{
                      transaction.count
                    }}</span>
                    {{ getTransactionDifference(transactionIndex) }}</span
                  >
                  <time :datetime="transaction.timestamp.seconds">{{
                    parseDateTime(transaction.timestamp.seconds)
                  }}</time>
                </span>
              </span>
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </a>
        </li>
      </ul>

      <nav
        class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
        aria-label="Pagination"
      >
        <div class="flex-1 flex justify-between">
          <a
            href="#"
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
          >
            Previous
          </a>
          <a
            href="#"
            class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
          >
            Next
          </a>
        </div>
      </nav>
    </div>

    <!-- Activity table (small breakpoint and up) -->
    <div class="hidden sm:block">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col mt-2">
          <div
            class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Activity
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Employee
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(transaction, transactionIndex) in transactionsPage"
                  :key="transaction.id"
                  class="bg-white"
                >
                  <td
                    class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                  >
                    <time :datetime="transaction.timestamp.seconds">{{
                      parseDateTime(transaction.timestamp.seconds)
                    }}</time>
                  </td>
                  <td
                    class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                  >
                    <div class="flex">
                      <a
                        :href="transaction.href"
                        class="group inline-flex space-x-2 truncate text-sm"
                      >
                        <CashIcon
                          class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <p
                          class="text-gray-500 truncate group-hover:text-gray-900"
                        >
                          {{ transaction.message }}
                        </p>
                      </a>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                  >
                    <span class="text-gray-900 font-medium"
                      >{{ transaction.name }}
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                  >
                    <span class="text-gray-900 font-medium"
                      >{{ transaction.count }}
                    </span>
                    {{ getTransactionDifference(transactionIndex) }}
                  </td>
                  <td
                    class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block"
                  >
                    <span
                      :class="[
                        statusStyles[transaction.status],
                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                      ]"
                    >
                      {{ transaction.status }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <nav
              class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div class="hidden sm:block">
                <p class="text-sm text-gray-700">
                  Showing
                  {{ " " }}
                  <span class="font-medium">1</span>
                  {{ " " }}
                  to
                  {{ " " }}
                  <span class="font-medium">10</span>
                  {{ " " }}
                  of
                  {{ " " }}
                  <span class="font-medium">{{ transactionsCount }}</span>
                  {{ " " }}
                  results
                </p>
              </div>
              <div class="flex-1 flex justify-between sm:justify-end">
                <a
                  href="#"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </a>
                <a
                  href="#"
                  class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Next
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <Modal />
  </div>
</template>
<script>
import {
  MenuAlt1Icon,
  XIcon,
  CubeIcon,
  TagIcon,
  ChartSquareBarIcon
} from "@heroicons/vue/outline";
import {
  ChevronRightIcon,
  CashIcon,
  CheckCircleIcon,
  LocationMarkerIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon
} from "@heroicons/vue/solid";

const statusStyles = {
  success: "bg-green-100 text-green-800",
  error: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800"
};

import Modal from "@/components/Modal.vue";
import CssSpinner from "@/components/CssSpinner.vue";

export default {
  name: "Home",
  components: {
    CashIcon,
    CheckCircleIcon,
    MenuAlt1Icon,
    LocationMarkerIcon,
    ChevronRightIcon,
    XIcon,
    Modal,
    CssSpinner,
    ArrowSmDownIcon,
    ArrowSmUpIcon
  },
  setup() {
    return {
      statusStyles
    };
  },
  computed: {
    cards() {
      return [
        {
          name: "Current Stock",
          href: "./history",
          icon: ChartSquareBarIcon,
          amount: this.transactionsPage[0]
            ? this.transactionsPage[0].count
            : "~",
          changeType:
            this.getTransactionDifference(0).indexOf("-") > -1
              ? "decrease"
              : this.getTransactionDifference(0).indexOf("+") > -1
              ? "increase"
              : false
        },
        {
          name: "Registered Baskets",
          href: "./baskets",
          icon: CubeIcon,
          amount:
            this.activeTrolley && this.activeTrolley.baskets
              ? this.activeTrolley.baskets.length
              : 0
        },
        {
          name: "Registered Tags",
          href: "./tags",
          icon: TagIcon,
          amount:
            this.activeTrolley && this.activeTrolley.tags
              ? this.activeTrolley.tags.length
              : 0
        }
      ];
    },
    activeTrolley() {
      return this.$store.state.activeTrolley;
    },
    transactionsPage() {
      return this.activeTrolley &&
        this.activeTrolley.transactions &&
        Array.isArray(this.activeTrolley.transactions)
        ? [...this.activeTrolley.transactions].reverse().slice(0, 9)
        : [];
    },
    transactions() {
      return this.activeTrolley &&
        this.activeTrolley.transactions &&
        Array.isArray(this.activeTrolley.transactions)
        ? [...this.activeTrolley.transactions].reverse()
        : [];
    },
    transactionsCount() {
      return this.activeTrolley &&
        this.activeTrolley.transactions &&
        Array.isArray(this.activeTrolley.transactions)
        ? this.activeTrolley.transactions.length
        : 0;
    },
    isReadInProgress() {
      if (
        this.activeTrolley &&
        this.activeTrolley.read &&
        this.activeTrolley.read.status &&
        (this.activeTrolley.read.status == "processing" ||
          this.activeTrolley.read.status == "done")
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    openModal() {
      this.$store.dispatch("setModal", true);
    },
    parseDateTime(timestamp) {
      var date = new Date(timestamp);
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    getTransactionDifference(index) {
      if (
        !this.transactions ||
        !this.transactions[index] ||
        !this.transactions[index + 1]
      )
        return "0";
      const last =
        index === this.transactions.length - 1
          ? 0
          : this.transactions[index + 1].count;
      const dif = this.transactions[index].count - last;
      if (dif < 0) return "-" + Math.abs(dif);
      if (dif === 0) return "0";
      return "+" + Math.abs(dif);
    }
  }
};
</script>
