import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6dm3StEU_jZkOJRoR7VpHQET4b74QgPM",
  authDomain: "mpo-demo-53179.firebaseapp.com",
  projectId: "mpo-demo-53179",
  storageBucket: "mpo-demo-53179.appspot.com",
  messagingSenderId: "230788709814",
  appId: "1:230788709814:web:68de9be7d6c7fc5e6f9517",
  measurementId: "G-LY70W7QBNN"
};

const db = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig).firestore()
  : firebase.app().firestore();

// Get a Firestore instance
export { db };
